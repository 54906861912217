/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Journey = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
            It's a Journey
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
            We understand that financial planning is a journey and our financial advisers are here to support and guide you every step of the way. When you engage with one of our advisers, you can expect a personalised and collaborative relationship built on trust and mutual understanding. We will work with you to understand your financial goals and provide the advice, guidance and resources you need to achieve your goals and objectives.
            <br/>
            <br/>
            Whether you are facing challenges or celebrating successes, we will be there for you, acting as your financial mentor, sounding board and friend.
            <br/>
            <br/>
            Our goal is to help you make informed financial decisions and pave the pathway towards your financial success and peace of mind.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
            Transparency and Integrity
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              When you appoint us as your financial adviser, it is essential that we work together to establish a relationship based on mutual trust and understanding.
              <br/>
              <br/>
              Your financial adviser is committed to acting with integrity and transparency, adhering to the regulations and standards set by the Financial Market Authority. It is equally important for you to be transparent with us about your financial situation and goals in order for us to provide you with tailored financial advice and recommendations that suit your circumstances and needs.
              <br/>
              <br/>
              By establishing a strong foundation of trust and transparency, we can work together to achieve your financial objectives and provide you with the peace of mind that comes with a well-planned financial future.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Journey;
