/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StrikethroughSSharp } from '@mui/icons-material';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Who are we?
            </Typography>
            <Typography component={'p'}>
              <Typography
                variant="span"
                sx={{ textDecoration: 'bold' }}
              >
                AIFP Personal Risk Limited T/A FG Life (FSP1003239)
                {/* Astute Investments and Financial Planning (FSP637389) Ltd T/A
                AIFP */}
              </Typography>
              {' '} is a financial advice provider which holds a Full Licence — Class
              2, issued by the Financial Market Authority (FMA), to provide the
              financial advice service.
              <br />
              <br />
              At {' '}
              <Typography
                variant="span"
                sx={{ textDecoration: 'bold' }}
              >
              FG Life {' '}
              </Typography>
              ,your financial adviser will be your partner throughout your financial planning journey, understanding your circumstances and needs in order to achieve your goals and objectives.
              <br />
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg'
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
