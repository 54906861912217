/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Vision
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
            We strive to provide our clients with the highest standards of care, and that starts with delivering high-quality financial advice. Our team of trustworthy and knowledgeable financial advisers is dedicated to providing our clients with the expertise and support they need to make informed decisions about their finances.
            <br/>
            <br/>
            Our aim is to be known as the epitome of financial advice, setting the highest standards in the industry for client care and helping our clients achieve their financial goals.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Mission
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              To help our clients meet their immediate financial needs and provide them with the knowledge and resources to achieve long-term financial stability and freedom. By providing comprehensive and tailored financial advice, we aim to enable our clients to make informed decisions about their finances and reach their financial goals.
              <br/>
              <br/>
              To offer a comprehensive financial plan that serves as a roadmap for our clients on their journey towards financial independence. We believe in providing a clear and achievable path for our clients to follow, so that they can make informed decisions about their finances and work towards their long-term financial goals. Our financial roadmap serves as a valuable tool for our clients, guiding them towards financial stability and freedom.
              <br/>
              <br/>
              To provide ongoing support and guidance to our clients throughout their financial journey. Our financial advisers serve as trusted partners, working with each client to educate them and provide the resources and information needed to make informed decisions about their finances. We believe in being there for our clients at every step, offering guidance and support to ensure that their journey towards financial stability and independence is as smooth and successful as possible.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
